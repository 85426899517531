<template>
  <div id="conteudo">
    <Titulo texto="Solda" opacityTitle="Serviços" :servico="true" />
    <cardServicos
      v-for="(conteudo, index) in conteudoCards"
      :key="index"
      :titulo="conteudo.titulo"
      :image="conteudo.imagem"
      :alt="conteudo.alt"
    >
      {{ conteudo.texto }}
    </cardServicos>
  </div>
</template>

<script>
import cardServicos from "../../components/cardServicos.vue";
import Titulo from "../../components/Titulo.vue";

export default {
  components: { cardServicos, Titulo },
  data() {
    return {
      conteudoCards: [
        {
          titulo: "Solda mig",
          texto: "Processo com objetivo de fundição de metais em aço. ",
          imagem: "solda/solda1.webp",
          alt: "Solda mig",
        },
        {
          titulo: "Solda tig",
          texto:
            "Processo com objetivo de fundição de metais em aço e alumínio. ",
          imagem: "solda/solda2.webp",
          alt: "Solda tig",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.estrutura {
  color: #ededed;
  h3 {
    font-family: Monument Extended, sans-serif;
    font-size: 2.25rem;
  }

  ul {
    font-size: 1.25rem;
    color: #05c8ef;
    padding-inline-start: 20px;

    li {
      p {
        color: #ededed;
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .estrutura {
    h3 {
      font-size: 1.6rem;
    }

    ul {
      font-size: 1rem;
    }
  }
}
</style>